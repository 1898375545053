import React from 'react';

import Layout from '../components/Layout/Layout';
import Subheader from '../components/Subheader/Subheader';
import DoYouKnowContent from '../components/DoYouKnowContent/DoYouKnowContent';

const DoYouKnow: React.FC = () => {
  return (
    <Layout>
      <Subheader title='Czy wiesz, że?' />
      <DoYouKnowContent />
    </Layout>
  );
};

export default DoYouKnow;
