// hero 1
import hero1_WEBP_2000px_2x from '../../assets/images/hero/hero-1_2000px_2x.webp';
import hero1_WEBP_1400px_2x from '../../assets/images/hero/hero-1_1400px_2x.webp';
import hero1_WEBP_800px_2x from '../../assets/images/hero/hero-1_800px_2x.webp';

import hero1_WEBP_2000px_1x from '../../assets/images/hero/hero-1_2000px_1x.webp';
import hero1_WEBP_1400px_1x from '../../assets/images/hero/hero-1_1400px_1x.webp';
import hero1_WEBP_800px_1x from '../../assets/images/hero/hero-1_800px_1x.webp';

import hero1_JPG_2000px_2x from '../../assets/images/hero/hero-1_2000px_2x.jpg';
import hero1_JPG_1400px_2x from '../../assets/images/hero/hero-1_1400px_2x.jpg';
import hero1_JPG_800px_2x from '../../assets/images/hero/hero-1_800px_2x.jpg';

import hero1_JPG_2000px_1x from '../../assets/images/hero/hero-1_2000px_1x.jpg';
import hero1_JPG_1400px_1x from '../../assets/images/hero/hero-1_1400px_1x.jpg';
import hero1_JPG_800px_1x from '../../assets/images/hero/hero-1_800px_1x.jpg';

// hero 2
import hero2_WEBP_2000px_2x from '../../assets/images/hero/hero-2_2000px_2x.webp';
import hero2_WEBP_1400px_2x from '../../assets/images/hero/hero-2_1400px_2x.webp';
import hero2_WEBP_800px_2x from '../../assets/images/hero/hero-2_800px_2x.webp';

import hero2_WEBP_2000px_1x from '../../assets/images/hero/hero-2_2000px_1x.webp';
import hero2_WEBP_1400px_1x from '../../assets/images/hero/hero-2_1400px_1x.webp';
import hero2_WEBP_800px_1x from '../../assets/images/hero/hero-2_800px_1x.webp';

import hero2_JPG_2000px_2x from '../../assets/images/hero/hero-2_2000px_2x.jpg';
import hero2_JPG_1400px_2x from '../../assets/images/hero/hero-2_1400px_2x.jpg';
import hero2_JPG_800px_2x from '../../assets/images/hero/hero-2_800px_2x.jpg';

import hero2_JPG_2000px_1x from '../../assets/images/hero/hero-2_2000px_1x.jpg';
import hero2_JPG_1400px_1x from '../../assets/images/hero/hero-2_1400px_1x.jpg';
import hero2_JPG_800px_1x from '../../assets/images/hero/hero-2_800px_1x.jpg';

// hero 3
import hero3_WEBP_2000px_2x from '../../assets/images/hero/hero-3_2000px_2x.webp';
import hero3_WEBP_1400px_2x from '../../assets/images/hero/hero-3_1400px_2x.webp';
import hero3_WEBP_800px_2x from '../../assets/images/hero/hero-3_800px_2x.webp';

import hero3_WEBP_2000px_1x from '../../assets/images/hero/hero-3_2000px_1x.webp';
import hero3_WEBP_1400px_1x from '../../assets/images/hero/hero-3_1400px_1x.webp';
import hero3_WEBP_800px_1x from '../../assets/images/hero/hero-3_800px_1x.webp';

import hero3_JPG_2000px_2x from '../../assets/images/hero/hero-3_2000px_2x.jpg';
import hero3_JPG_1400px_2x from '../../assets/images/hero/hero-3_1400px_2x.jpg';
import hero3_JPG_800px_2x from '../../assets/images/hero/hero-3_800px_2x.jpg';

import hero3_JPG_2000px_1x from '../../assets/images/hero/hero-3_2000px_1x.jpg';
import hero3_JPG_1400px_1x from '../../assets/images/hero/hero-3_1400px_1x.jpg';
import hero3_JPG_800px_1x from '../../assets/images/hero/hero-3_800px_1x.jpg';

// hero 4
import hero4_WEBP_2000px_2x from '../../assets/images/hero/hero-4_2000px_2x.webp';
import hero4_WEBP_1400px_2x from '../../assets/images/hero/hero-4_1400px_2x.webp';
import hero4_WEBP_800px_2x from '../../assets/images/hero/hero-4_800px_2x.webp';

import hero4_WEBP_2000px_1x from '../../assets/images/hero/hero-4_2000px_1x.webp';
import hero4_WEBP_1400px_1x from '../../assets/images/hero/hero-4_1400px_1x.webp';
import hero4_WEBP_800px_1x from '../../assets/images/hero/hero-4_800px_1x.webp';

import hero4_JPG_2000px_2x from '../../assets/images/hero/hero-4_2000px_2x.jpg';
import hero4_JPG_1400px_2x from '../../assets/images/hero/hero-4_1400px_2x.jpg';
import hero4_JPG_800px_2x from '../../assets/images/hero/hero-4_800px_2x.jpg';

import hero4_JPG_2000px_1x from '../../assets/images/hero/hero-4_2000px_1x.jpg';
import hero4_JPG_1400px_1x from '../../assets/images/hero/hero-4_1400px_1x.jpg';
import hero4_JPG_800px_1x from '../../assets/images/hero/hero-4_800px_1x.jpg';

// hero 5
import hero5_WEBP_2000px_2x from '../../assets/images/hero/hero-5_2000px_2x.webp';
import hero5_WEBP_1400px_2x from '../../assets/images/hero/hero-5_1400px_2x.webp';
import hero5_WEBP_800px_2x from '../../assets/images/hero/hero-5_800px_2x.webp';

import hero5_WEBP_2000px_1x from '../../assets/images/hero/hero-5_2000px_1x.webp';
import hero5_WEBP_1400px_1x from '../../assets/images/hero/hero-5_1400px_1x.webp';
import hero5_WEBP_800px_1x from '../../assets/images/hero/hero-5_800px_1x.webp';

import hero5_JPG_2000px_2x from '../../assets/images/hero/hero-5_2000px_2x.jpg';
import hero5_JPG_1400px_2x from '../../assets/images/hero/hero-5_1400px_2x.jpg';
import hero5_JPG_800px_2x from '../../assets/images/hero/hero-5_800px_2x.jpg';

import hero5_JPG_2000px_1x from '../../assets/images/hero/hero-5_2000px_1x.jpg';
import hero5_JPG_1400px_1x from '../../assets/images/hero/hero-5_1400px_1x.jpg';
import hero5_JPG_800px_1x from '../../assets/images/hero/hero-5_800px_1x.jpg';

// hero 6
import hero6_WEBP_2000px_2x from '../../assets/images/hero/hero-6_2000px_2x.webp';
import hero6_WEBP_1400px_2x from '../../assets/images/hero/hero-6_1400px_2x.webp';
import hero6_WEBP_800px_2x from '../../assets/images/hero/hero-6_800px_2x.webp';

import hero6_WEBP_2000px_1x from '../../assets/images/hero/hero-6_2000px_1x.webp';
import hero6_WEBP_1400px_1x from '../../assets/images/hero/hero-6_1400px_1x.webp';
import hero6_WEBP_800px_1x from '../../assets/images/hero/hero-6_800px_1x.webp';

import hero6_JPG_2000px_2x from '../../assets/images/hero/hero-6_2000px_2x.jpg';
import hero6_JPG_1400px_2x from '../../assets/images/hero/hero-6_1400px_2x.jpg';
import hero6_JPG_800px_2x from '../../assets/images/hero/hero-6_800px_2x.jpg';

import hero6_JPG_2000px_1x from '../../assets/images/hero/hero-6_2000px_1x.jpg';
import hero6_JPG_1400px_1x from '../../assets/images/hero/hero-6_1400px_1x.jpg';
import hero6_JPG_800px_1x from '../../assets/images/hero/hero-6_800px_1x.jpg';

// hero 7
import hero7_WEBP_2000px_2x from '../../assets/images/hero/hero-7_2000px_2x.webp';
import hero7_WEBP_1400px_2x from '../../assets/images/hero/hero-7_1400px_2x.webp';
import hero7_WEBP_800px_2x from '../../assets/images/hero/hero-7_800px_2x.webp';

import hero7_WEBP_2000px_1x from '../../assets/images/hero/hero-7_2000px_1x.webp';
import hero7_WEBP_1400px_1x from '../../assets/images/hero/hero-7_1400px_1x.webp';
import hero7_WEBP_800px_1x from '../../assets/images/hero/hero-7_800px_1x.webp';

import hero7_JPG_2000px_2x from '../../assets/images/hero/hero-7_2000px_2x.jpg';
import hero7_JPG_1400px_2x from '../../assets/images/hero/hero-7_1400px_2x.jpg';
import hero7_JPG_800px_2x from '../../assets/images/hero/hero-7_800px_2x.jpg';

import hero7_JPG_2000px_1x from '../../assets/images/hero/hero-7_2000px_1x.jpg';
import hero7_JPG_1400px_1x from '../../assets/images/hero/hero-7_1400px_1x.jpg';
import hero7_JPG_800px_1x from '../../assets/images/hero/hero-7_800px_1x.jpg';

// hero 8
import hero8_WEBP_2000px_2x from '../../assets/images/hero/hero-8_2000px_2x.webp';
import hero8_WEBP_1400px_2x from '../../assets/images/hero/hero-8_1400px_2x.webp';
import hero8_WEBP_800px_2x from '../../assets/images/hero/hero-8_800px_2x.webp';

import hero8_WEBP_2000px_1x from '../../assets/images/hero/hero-8_2000px_1x.webp';
import hero8_WEBP_1400px_1x from '../../assets/images/hero/hero-8_1400px_1x.webp';
import hero8_WEBP_800px_1x from '../../assets/images/hero/hero-8_800px_1x.webp';

import hero8_JPG_2000px_2x from '../../assets/images/hero/hero-8_2000px_2x.jpg';
import hero8_JPG_1400px_2x from '../../assets/images/hero/hero-8_1400px_2x.jpg';
import hero8_JPG_800px_2x from '../../assets/images/hero/hero-8_800px_2x.jpg';

import hero8_JPG_2000px_1x from '../../assets/images/hero/hero-8_2000px_1x.jpg';
import hero8_JPG_1400px_1x from '../../assets/images/hero/hero-8_1400px_1x.jpg';
import hero8_JPG_800px_1x from '../../assets/images/hero/hero-8_800px_1x.jpg';

export type ImageType =
  | 'hero1'
  | 'hero2'
  | 'hero3'
  | 'hero4'
  | 'hero5'
  | 'hero6'
  | 'hero7'
  | 'hero8';

type ImageSources = {
  [key in ImageType]: {
    webp: {
      '800px': {
        '1x': string;
        '2x': string;
      };
      '1400px': {
        '1x': string;
        '2x': string;
      };
      '2000px': {
        '1x': string;
        '2x': string;
      };
    };
    jpg: {
      '800px': {
        '1x': string;
        '2x': string;
      };
      '1400px': {
        '1x': string;
        '2x': string;
      };
      '2000px': {
        '1x': string;
        '2x': string;
      };
    };
  };
};

export const imageSources: ImageSources = {
  hero1: {
    webp: {
      '800px': {
        '1x': hero1_WEBP_800px_1x,
        '2x': hero1_WEBP_800px_2x,
      },
      '1400px': {
        '1x': hero1_WEBP_1400px_1x,
        '2x': hero1_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': hero1_WEBP_2000px_1x,
        '2x': hero1_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': {
        '1x': hero1_JPG_800px_1x,
        '2x': hero1_JPG_800px_2x,
      },
      '1400px': {
        '1x': hero1_JPG_1400px_1x,
        '2x': hero1_JPG_1400px_2x,
      },
      '2000px': {
        '1x': hero1_JPG_2000px_1x,
        '2x': hero1_JPG_2000px_2x,
      },
    },
  },
  hero2: {
    webp: {
      '800px': {
        '1x': hero2_WEBP_800px_1x,
        '2x': hero2_WEBP_800px_2x,
      },
      '1400px': {
        '1x': hero2_WEBP_1400px_1x,
        '2x': hero2_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': hero2_WEBP_2000px_1x,
        '2x': hero2_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': {
        '1x': hero2_JPG_800px_1x,
        '2x': hero2_JPG_800px_2x,
      },
      '1400px': {
        '1x': hero2_JPG_1400px_1x,
        '2x': hero2_JPG_1400px_2x,
      },
      '2000px': {
        '1x': hero2_JPG_2000px_1x,
        '2x': hero2_JPG_2000px_2x,
      },
    },
  },
  hero3: {
    webp: {
      '800px': {
        '1x': hero3_WEBP_800px_1x,
        '2x': hero3_WEBP_800px_2x,
      },
      '1400px': {
        '1x': hero3_WEBP_1400px_1x,
        '2x': hero3_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': hero3_WEBP_2000px_1x,
        '2x': hero3_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': {
        '1x': hero3_JPG_800px_1x,
        '2x': hero3_JPG_800px_2x,
      },
      '1400px': {
        '1x': hero3_JPG_1400px_1x,
        '2x': hero3_JPG_1400px_2x,
      },
      '2000px': {
        '1x': hero3_JPG_2000px_1x,
        '2x': hero3_JPG_2000px_2x,
      },
    },
  },
  hero4: {
    webp: {
      '800px': {
        '1x': hero4_WEBP_800px_1x,
        '2x': hero4_WEBP_800px_2x,
      },
      '1400px': {
        '1x': hero4_WEBP_1400px_1x,
        '2x': hero4_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': hero4_WEBP_2000px_1x,
        '2x': hero4_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': {
        '1x': hero4_JPG_800px_1x,
        '2x': hero4_JPG_800px_2x,
      },
      '1400px': {
        '1x': hero4_JPG_1400px_1x,
        '2x': hero4_JPG_1400px_2x,
      },
      '2000px': {
        '1x': hero4_JPG_2000px_1x,
        '2x': hero4_JPG_2000px_2x,
      },
    },
  },
  hero5: {
    webp: {
      '800px': {
        '1x': hero5_WEBP_800px_1x,
        '2x': hero5_WEBP_800px_2x,
      },
      '1400px': {
        '1x': hero5_WEBP_1400px_1x,
        '2x': hero5_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': hero5_WEBP_2000px_1x,
        '2x': hero5_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': {
        '1x': hero5_JPG_800px_1x,
        '2x': hero5_JPG_800px_2x,
      },
      '1400px': {
        '1x': hero5_JPG_1400px_1x,
        '2x': hero5_JPG_1400px_2x,
      },
      '2000px': {
        '1x': hero5_JPG_2000px_1x,
        '2x': hero5_JPG_2000px_2x,
      },
    },
  },
  hero6: {
    webp: {
      '800px': {
        '1x': hero6_WEBP_800px_1x,
        '2x': hero6_WEBP_800px_2x,
      },
      '1400px': {
        '1x': hero6_WEBP_1400px_1x,
        '2x': hero6_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': hero6_WEBP_2000px_1x,
        '2x': hero6_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': {
        '1x': hero6_JPG_800px_1x,
        '2x': hero6_JPG_800px_2x,
      },
      '1400px': {
        '1x': hero6_JPG_1400px_1x,
        '2x': hero6_JPG_1400px_2x,
      },
      '2000px': {
        '1x': hero6_JPG_2000px_1x,
        '2x': hero6_JPG_2000px_2x,
      },
    },
  },
  hero7: {
    webp: {
      '800px': {
        '1x': hero7_WEBP_800px_1x,
        '2x': hero7_WEBP_800px_2x,
      },
      '1400px': {
        '1x': hero7_WEBP_1400px_1x,
        '2x': hero7_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': hero7_WEBP_2000px_1x,
        '2x': hero7_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': {
        '1x': hero7_JPG_800px_1x,
        '2x': hero7_JPG_800px_2x,
      },
      '1400px': {
        '1x': hero7_JPG_1400px_1x,
        '2x': hero7_JPG_1400px_2x,
      },
      '2000px': {
        '1x': hero7_JPG_2000px_1x,
        '2x': hero7_JPG_2000px_2x,
      },
    },
  },
  hero8: {
    webp: {
      '800px': {
        '1x': hero8_WEBP_800px_1x,
        '2x': hero8_WEBP_800px_2x,
      },
      '1400px': {
        '1x': hero8_WEBP_1400px_1x,
        '2x': hero8_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': hero8_WEBP_2000px_1x,
        '2x': hero8_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': {
        '1x': hero8_JPG_800px_1x,
        '2x': hero8_JPG_800px_2x,
      },
      '1400px': {
        '1x': hero8_JPG_1400px_1x,
        '2x': hero8_JPG_1400px_2x,
      },
      '2000px': {
        '1x': hero8_JPG_2000px_1x,
        '2x': hero8_JPG_2000px_2x,
      },
    },
  },
};
