import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import hero1 from '../../assets/images/hero-1.jpg';
import hero2 from '../../assets/images/hero-2.jpg';
import hero7 from '../../assets/images/hero-7.jpg';
import hero3 from '../../assets/images/hero-3.jpg';
import hero4 from '../../assets/images/hero-4.jpg';
import hero5 from '../../assets/images/hero-5.jpg';
import hero6 from '../../assets/images/hero-6.jpg';
import hero8 from '../../assets/images/hero-8.jpg';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './HeroImages.scss';
import { useContrast } from '../../context/ContrastContext';
import Text from '../core/Text/Text';
import Icon from '../core/Icon/Icon';
import { scrollToElementWithOffset } from '../../utils/scrollToElementWithOffset';
import useMediaQuery from '../../hooks/useMediaQuery';

const HeroImages: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [flipped, setFlipped] = useState<boolean[]>(Array(5).fill(false));
  const [mainImages, setMainImages] = useState([
    hero7,
    hero5,
    hero4,
    hero6,
    hero3,
  ]);
  const { highContrast } = useContrast();

  const allImages = [hero1, hero2, hero3, hero4, hero5, hero6, hero7, hero8];
  const imagesPerCycle = 5;

  useEffect(() => {
    if (!isMobile) {
      const rotateImages = () => {
        const newImages: string[] = [];

        while (newImages.length < imagesPerCycle) {
          const randomIndex = Math.floor(Math.random() * allImages.length);
          const randomImage = allImages[randomIndex];

          if (!newImages.includes(randomImage)) {
            newImages.push(randomImage);
          }
        }

        setMainImages(newImages);
      };

      rotateImages();
      const interval = setInterval(rotateImages, 5000);

      return () => clearInterval(interval);
    }
  }, [isMobile]);

  const onChevronClick = () => {
    scrollToElementWithOffset({ selector: 'news' });
  };

  const handleImageClick = (index: number) => {
    const newFlipped = [...flipped];
    newFlipped[index] = !newFlipped[index];
    setFlipped(newFlipped);

    if (newFlipped[index]) {
      setTimeout(() => {
        setFlipped((currentFlipped) => {
          const updatedFlipped = [...currentFlipped];
          updatedFlipped[index] = false;
          return updatedFlipped;
        });
      }, 5000); // Revert after 5 seconds
    }
  };

  if (isMobile) {
    return (
      <div className={classNames('hero-images-mobile', { highContrast })}>
        <img src={hero7} alt='Hero 1' className='image-1-mobile' />
        <div className={classNames('hero-message', { highContrast })}>
          <div className='hero-message-texts'>
            <Text
              fontSize={isMobile ? '1.25rem' : '2rem'}
              isBold
              as='h1'
              className='hero-text main-text'
            >
              Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu
            </Text>
            <div className='cta' onClick={onChevronClick}>
              <Text isBold as='h2' className='hero-text secondary-text'>
                Odkryj wyjątkową atmosferę naszego osiedla w kolorze niebieskim
              </Text>

              <Icon
                className={classNames('chevronDown', {
                  highContrast: highContrast,
                })}
                icon={faChevronDown}
                size='1x'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames('hero-images-grid', {
        'high-contrast': highContrast,
      })}
    >
      {mainImages.map((image, index) => (
        <div
          key={index}
          className={classNames(`image-${index + 1}`, 'image-container', {
            flipped: flipped[index],
          })}
          onClick={() => handleImageClick(index)}
        >
          <div className='image-front'>
            <img src={image} alt={`Obrazek ${index + 1}`} />
          </div>
          <div className='image-back'>
            <Text className='image-back-content'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit
            </Text>
          </div>
        </div>
      ))}

      <div className={classNames('hero-message', { highContrast })}>
        <div className='hero-message-texts'>
          <Text fontSize='2rem' isBold as='h1' className='hero-text main-text'>
            Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu
          </Text>
          <div className='cta' onClick={onChevronClick}>
            <Text isBold as='h2' className='hero-text secondary-text'>
              Odkryj wyjątkową atmosferę naszego osiedla w kolorze niebieskim
            </Text>

            <Icon
              className={classNames('chevronDown', {
                highContrast: highContrast,
              })}
              icon={faChevronDown}
              size='1x'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImages;
