export enum Paths {
  HOME = '/',
  NEWS = '/ogloszenia',
  ABOUT = '/o-nas',
  CONTACT = '/kontakt',
  WASTE_SEGREGATION = '/segregacja-odpadow',
  WARM = '/cieplo-krotki-przewodnik',
  POST = '/post',
  PETS = '/odpowiedzialnosc-wlascicieli-zwierzat',
  SECURITY = '/stop-wlamaniom',
  EMERGENCY = '/numery-alarmowe',
  RESIDENT = '/strefa-mieszkanca',
  DO_YOU_KNOW = '/czy-wiesz-ze',
}
