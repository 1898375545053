import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { useContrast } from '../../../context/ContrastContext';
import Icon from '../../core/Icon/Icon';

import './ScrollToTop.scss';

const ScrollToTop: React.FC = () => {
  const { highContrast } = useContrast();

  const [showScroll, setShowScroll] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  const calculateScrollProgress = useCallback(() => {
    const scrollTop = window.pageYOffset;
    const scrollHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const progress = (scrollTop / scrollHeight) * 100;
    setScrollProgress(progress);
  }, []);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const onScroll = () => {
      checkScrollTop();
      calculateScrollProgress();
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [checkScrollTop, calculateScrollProgress]);

  return (
    <button
      className={classNames('scrollTop', { highContrast })}
      onClick={scrollTop}
      style={{
        display: showScroll ? 'flex' : 'none',
        background: `conic-gradient(
          ${
            highContrast ? 'var(--spol-white)' : 'var(--spol-primary)'
          } ${scrollProgress}%,
          ${
            highContrast ? 'var(--spol-grey-light)' : 'rgba(0, 56, 123, 0.5)'
          } ${scrollProgress}%
        )`,
      }}
    >
      <Icon icon={faArrowUp} />
    </button>
  );
};

export default ScrollToTop;
