import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';

import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import News from './pages/News';
import WasteSegregation from './pages/WasteSegregation';
import PostPage from './pages/PostPage';
import Emergency from './pages/Emergency';
import { Paths } from './constants/paths';
import Warm from './pages/Warm';
import Pets from './pages/Pets';
import Security from './pages/Security';
import Resident from './pages/Resident';
import DoYouKnow from './pages/DoYouKnow';

library.add(fas);

const App: React.FC = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route
        path='/wp-content/themes/happy-coding-react-theme'
        element={<Navigate to='/' />}
      />
      <Route path={Paths.HOME} element={<Home />} />
      <Route path={Paths.NEWS} element={<News />} />
      <Route path={Paths.ABOUT} element={<About />} />
      <Route path={Paths.CONTACT} element={<Contact />} />
      <Route path={Paths.RESIDENT} element={<Resident />} />
      <Route path={Paths.WASTE_SEGREGATION} element={<WasteSegregation />} />
      <Route path={`${Paths.POST}/:id`} element={<PostPage />} />
      <Route path={Paths.WARM} element={<Warm />} />
      <Route path={Paths.PETS} element={<Pets />} />
      <Route path={Paths.SECURITY} element={<Security />} />
      <Route path={Paths.EMERGENCY} element={<Emergency />} />
      <Route path={Paths.DO_YOU_KNOW} element={<DoYouKnow />} />
    </Routes>
  );
};

export default App;
