import React from 'react';

import Layout from '../components/Layout/Layout';
import Subheader from '../components/Subheader/Subheader';
import WarmInfo from '../components/WarmInfo/WarmInfo';

const Warm: React.FC = () => {
  return (
    <Layout hasBackButton>
      <Subheader title='Jednym słowem - ciepło. Krótki przewodnik.' />
      <WarmInfo />
    </Layout>
  );
};

export default Warm;
