import React from 'react';

import Layout from '../components/Layout/Layout';
import MarkedPosts from '../components/MarkedPosts/MarkedPosts';
import Posts from '../components/Posts/Posts';
import SocialAndEdu from '../components/SocialAndEdu/SocialAndEdu';
import Subheader from '../components/Subheader/Subheader';
import HeroImages from '../components/HeroImages/HeroImages';
import './Home.scss';
import Text from '../components/core/Text/Text';

const Home: React.FC = () => {
  const photosBy = (
    <div className='home-photos-by'>
      <Text fontSize='0.8rem'>
        Zdjęcia wykorzystane na tej stronie stanowią własność Pana Michała
        XXXXXXXX. Serdecznie dziękujemy za możliwość ich publikacji.
      </Text>
    </div>
  );

  return (
    <Layout>
      <section className='hero'>
        <HeroImages />
      </section>
      <section className='home-content'>
        <div id='news'>
          <Subheader title='Ogłoszenia' />
        </div>
        <Posts categoryId={34} hasPagination />
        <Subheader title='W trosce o wspólne dobro' />
        <MarkedPosts />
        <Subheader title='Działalność społeczno-wychowawcza' />
        <SocialAndEdu />
        {photosBy}
      </section>
    </Layout>
  );
};

export default Home;
