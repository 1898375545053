import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Text from '../../components/core/Text/Text';
import { wasteTypes } from './data';
import useMediaQuery from '../../hooks/useMediaQuery';
import './WasteSegregationRules.scss';

type WasteType =
  | 'metals'
  | 'paper'
  | 'glass'
  | 'mixed'
  | 'biodegradable'
  | 'textiles';

interface WasteSegregationRulesProps {
  type: WasteType;
}

const WasteSegregationRules: React.FC<WasteSegregationRulesProps> = ({
  type,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [activeTab, setActiveTab] = useState<'items' | 'notItems'>('items');
  const wasteData = wasteTypes[type];

  useEffect(() => {
    if (!isMobile) {
      setActiveTab('items');
    }
  }, [isMobile]);

  if (!wasteData) {
    return null;
  }

  const { icon, color, title, items, notItems } = wasteData;

  if (isMobile) {
    return (
      <div className={`waste-container ${color}`}>
        <div className='waste-header'>
          <FontAwesomeIcon
            size='2x'
            icon={icon}
            className={`waste-icon ${color}`}
          />
          <Text isBold as='h2'>
            {title}
          </Text>
        </div>
        <div className='tabs'>
          <button
            className={
              activeTab === 'items' ? 'tab-button active' : 'tab-button'
            }
            onClick={() => setActiveTab('items')}
          >
            Wrzucamy
          </button>
          <button
            className={
              activeTab === 'notItems' ? 'tab-button active' : 'tab-button'
            }
            onClick={() => setActiveTab('notItems')}
          >
            Nie wrzucamy
          </button>
        </div>
        <div className='tab-content'>
          <div
            className={`tab-panel ${
              activeTab === 'items' ? ' tab-button active' : 'tab-button'
            }`}
          >
            <ul>
              {items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div
            className={`tab-panel ${
              activeTab === 'notItems' ? 'tab-button active' : 'tab-button'
            }`}
          >
            <ul>
              {notItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`waste-container ${color}`}>
      <div className='waste-header'>
        <FontAwesomeIcon
          size='2x'
          icon={icon}
          className={`waste-icon ${color}`}
        />
        <Text isBold as='h2'>
          {title}
        </Text>
      </div>
      <div className='tabs'></div>
      <div className='tab-content'>
        <div
          className={`tab-panel ${
            activeTab === 'items' ? ' tab-button active' : 'tab-button'
          }`}
        >
          <Text className='waste-items-header' isBold>
            Wrzucamy
          </Text>
          <ul>
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div
          className={`tab-panel ${
            activeTab === 'notItems' ? 'tab-button active' : 'tab-button'
          }`}
        >
          <Text className='waste-items-header' isBold>
            Nie wrzucamy
          </Text>
          <ul>
            {notItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WasteSegregationRules;
