import React from 'react';

import Posts from '../Posts/Posts';
import Subheader from '../Subheader/Subheader';
import ContactInfoClub from '../ContactInfoClub/ContactInfoClub';

const Club: React.FC = () => {
  return (
    <div className='club'>
      <ContactInfoClub />
      <Subheader small title='Zajęcia stałe' />
      <Posts categoryId={12} hasPagination />
      <Subheader small title='Wydarzenia' />
      <Posts categoryId={11} hasPagination />
    </div>
  );
};

export default Club;
