import React from 'react';

import './DoYouKnowContent.scss';
import Posts from '../Posts/Posts';

const DoYouKnowContent: React.FC = () => {
  return (
    <div className='do-you-know'>
      <Posts categoryId={33} hasPagination />
    </div>
  );
};

export default DoYouKnowContent;
