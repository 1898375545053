import React, { useEffect, useRef } from 'react';
import Event from '../Event/Event';
import './History.scss';
import useMediaQuery from '../../hooks/useMediaQuery';
import classNames from 'classnames';
import Text from '../core/Text/Text';
import type { HistoryItem } from '../../data/historyItems';
import { useContrast } from '../../context/ContrastContext';

interface HistoryProps {
  items: HistoryItem[];
}

const History: React.FC<HistoryProps> = ({ items }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const observer = useRef<IntersectionObserver | null>(null);
  const { highContrast } = useContrast();

  const photosBy = (
    <div className='history-photos-by'>
      <Text fontSize='0.8rem'>
        Zdjęcia wykorzystane na tej podstronie pochodzą z książki autorstwa Pana
        Jakuba Nabiałka i zostały udostępnione dzięki jego uprzejmości.
        Serdecznie dziękujemy za możliwość ich publikacji.
      </Text>
    </div>
  );

  useEffect(() => {
    if (!isMobile) {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('in-view');
            } else {
              entry.target.classList.remove('in-view');
            }
          });
        },
        { threshold: 0.1 }
      );

      const elements = document.querySelectorAll('.timeline-item');
      elements.forEach((el, index) => {
        (el as HTMLElement).style.transitionDelay = `${index * 0.1}s`;
        observer.current?.observe(el);
      });

      return () => {
        observer.current?.disconnect();
      };
    }
  }, [isMobile]);

  if (isMobile) {
    return (
      <>
        <div className='history-container'>
          {items.map((item, index) => (
            <div key={index} className='timeline-item-mobile'>
              <Event
                bottom={item.bottom}
                date={item.date}
                title={item.title}
                description={item.description}
              />
              <img src={item.image} alt='' width='100%' height='auto' />
            </div>
          ))}
        </div>
        {photosBy}
      </>
    );
  }

  return (
    <>
      <div className='history-container'>
        <div className='timeline'>
          {items.map((item, index) => (
            <div
              key={index}
              className={`timeline-item ${index % 2 === 0 ? 'right' : 'left'}`}
            >
              <div className={classNames('timeline-icon', { highContrast })}>
                {item.icon}
              </div>
              <div className='timeline-image-wrapper'>
                <img
                  className={`image ${index % 2 === 0 ? 'right' : 'left'}`}
                  src={item.image}
                  alt=''
                  width='auto'
                  height='100%'
                />
                <Event
                  bottom={item.bottom}
                  date={item.date}
                  title={item.title}
                  description={item.description}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {photosBy}
    </>
  );
};

export default History;
