import React from 'react';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from '@vis.gl/react-google-maps';
import './GoogleMapProvider.scss';

import logo from '../../../assets/images/logoOld.png';

interface GoogleMapComponentProps {
  lat: number;
  lng: number;
}

const GoogleMapProvider: React.FC<GoogleMapComponentProps> = ({ lat, lng }) => {
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
      <div className='map-container'>
        <Map
          defaultZoom={15}
          defaultCenter={{ lat, lng }}
          disableDefaultUI
          gestureHandling='none'
          mapId='SM_NOWA_MAP'
        >
          <AdvancedMarker position={{ lat, lng }}>
            <img
              src={logo}
              alt='Logo Spółdzielnia'
              className='map-pin-logo-image'
            />
          </AdvancedMarker>
        </Map>
      </div>
    </APIProvider>
  );
};

export default GoogleMapProvider;
