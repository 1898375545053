import React from 'react';
import hero2 from '../../assets/images/hero/hero-2_1x.webp';
import hero5 from '../../assets/images/hero/hero-5_1x.webp';
import hero8 from '../../assets/images/hero/hero-8_1x.webp';
import hero6 from '../../assets/images/hero/hero-6_1x.webp';
import { Paths } from '../../constants/paths';
import Teaser from '../Teaser/Teaser';

import './MarkedPosts.scss';

const MarkedPosts: React.FC = () => {
  return (
    <div className='teaser-container'>
      <Teaser
        title='Jak segregować śmieci?'
        text='Segregowanie śmieci to jeden z najprostszych sposobów, w jaki możemy pomóc chronić naszą planetę. Dowiedz się więcej o korzyściach wynikających z recyklingu i jak prawidłowo segregować odpady.'
        link={Paths.WASTE_SEGREGATION}
        image={hero5}
      />
      <Teaser
        title='Jak oszczędzać energię cieplną?'
        text='Każdy chciałby, aby jego mieszkanie było ciepłe i przytulne. Dowiedz się, jak zmniejszyć rachunki za ogrzewanie i efektywnie wykorzystywać energię cieplną. Poznaj proste sposoby na oszczędzanie energii i zapewnienie komfortu cieplnego w swoim domu.'
        link={Paths.WARM}
        image={hero8}
      />
      <Teaser
        title='Odpowiedzialność właścicieli zwierząt'
        text='Pamiętaj, że jesteś odpowiedzialny za swojego pupila! Dowiedz się, jakie są obowiązki właściciela domowego pupila.'
        link={Paths.PETS}
        image={hero6}
      />
      <Teaser
        title='Jak zabezpieczyć mieszkanie przed włamaniami?'
        text='Dowiedz się, jak skutecznie zabezpieczyć swoje mieszkanie, aby zmniejszyć ryzyko włamania i zapewnić sobie spokój. Przestrzegając kilku prostych zasad, można znacznie zwiększyć bezpieczeństwo swojego domu.'
        link={Paths.SECURITY}
        image={hero2}
      />
    </div>
  );
};

export default MarkedPosts;
