import React from 'react';
import './LightGreyBall.scss';

const LightGreyBall: React.FC<{
  size?: number;
  fromTop: string;
  fromLeft: string;
  sizeUp?: number;
  opacity?: number;
}> = ({ fromTop, fromLeft, size = 270, sizeUp, opacity = 1 }) => {
  const scale = sizeUp ? sizeUp / size : 1;

  return (
    <div
      style={
        {
          position: 'absolute',
          left: fromLeft,
          top: fromTop,
          opacity,
          '--scale': scale,
        } as React.CSSProperties
      }
      className='ball-wrapper size-up'
    >
      <svg
        width={size}
        height={size}
        viewBox='0 0 100 100'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='50' cy='50' r='50' fill='#F2F6FA' />
      </svg>
    </div>
  );
};

export default LightGreyBall;
