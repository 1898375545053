import React from 'react';

import Layout from '../components/Layout/Layout';
import SinglePost from '../components/SinglePost/SinglePost';

const PostPage: React.FC = () => {
  return (
    <Layout hasBackButton>
      <SinglePost />
    </Layout>
  );
};

export default PostPage;
